import CarBrand from '@/models/car/CarBrand'
import Customer from '@/models/customer/Customer'
import Driver from '@/models/car/Driver'

export default class Car {
  public id: number
  public licenseplate: string
  public year: number
  public model: string
  public mileage: string

  public brand: CarBrand
  public customer: Customer
  public driver: Driver

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.licenseplate = data.licenseplate ? (data.licenseplate as string) : null
    this.year = data.year ? (data.year as number) : null
    this.model = data.model ? (data.model as string) : null
    this.mileage = data.mileage ? (data.mileage as string) : null

    this.brand = data.brand ? new CarBrand(data.brand as Record<string, unknown>) : null
    this.customer = data.customer ? new Customer(data.customer as Record<string, unknown>) : null
    this.driver = data.driver ? new Driver(data.driver as Record<string, unknown>) : null
  }

  public clone(): Car {
    const result = new Car()
    Object.assign(result, this)
    return result
  }
}
